import Vue from 'vue'
import Vuex from 'vuex'
const user = JSON.parse(sessionStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }


Vue.use(Vuex)

   const state = { 
        
        contratos: [],
        cliente: {},
        contratoselecionado: {},


     
    }

    const mutations = {

            setCliente(state, payload){
                state.cliente = payload
            },
            setContrato(state, payload){
                state.contratoselecionado = payload
            },
            setListaContratos(state, payload){
                state.contratos = (payload);
            },
          
    }

    const getters = {
        lista_contratos: state => state.contratos,
        cliente_select: state => state.cliente,
        contratoselecionado: state => state.contratoselecionado,
    }



    export default new Vuex.Store({
        state,
        mutations,
        getters,
        actions: {
        },
      })
