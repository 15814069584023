<template>
   
    <component :is="component">
        <slot/>
    </component>

 
</template>

<script>
    import VuebaseLayout from './layouts/VuebaseLayout'
    //import VuebaseLayoutLogin from './layouts/VuebaseLayoutLogin'

    export default {
        name: 'App',

        components: {
            VuebaseLayout
        },

        computed: {
            component() {
                return VuebaseLayout
            }
        }
    }
</script>
